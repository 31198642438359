<template>
    <div id="reset-password">
        <div class="container">
            <h1>
                Enter New Password
            </h1>
            <FormulateForm @submit="resetPassword" #default="{ isLoading, hasErrors }">
                <div class="input-container">
                    <Label>New Password</Label>
                    <FormulateInput
                        type="password"
                        name="password"
                        class="input"
                        placeholder="Create Password"
                        validation="^required|min:8,length|checkLetters|checkSpecialCharacters"
                        :validation-rules="{
                            checkLetters: ({ value }) => value.match(/^(?=.*[a-zA-Z])(?=.*\d).+$/g),
                            checkSpecialCharacters: ({ value }) => value.match(/^.*[!@#$%^&*()_+].*$/g)
                        }"
                        validation-name="Password"
                        :validation-messages="{
                            checkLetters: 'Password requires at least 1 number and 1 letter.',
                            checkSpecialCharacters: 'Password requires at least 1 special character (!, @, #, etc.)'
                        }"
                    />
                </div>
                <div class="input-container">
                    <Label>Confirm Password</Label>
                    <FormulateInput
                        type="password"
                        name="passwordConfirmation"
                        class="input"
                        placeholder="Create Password"
                        validation="^required|min:8,length|checkLetters|checkSpecialCharacters"
                        :validation-rules="{
                            checkLetters: ({ value }) => value.match(/^(?=.*[a-zA-Z])(?=.*\d).+$/g),
                            checkSpecialCharacters: ({ value }) => value.match(/^.*[!@#$%^&*()_+].*$/g)
                        }"
                        validation-name="Password"
                        :validation-messages="{
                            checkLetters: 'Password requires at least 1 number and 1 letter.',
                            checkSpecialCharacters: 'Password requires at least 1 special character (!, @, #, etc.)'
                        }"
                    />
                </div>
                <ul class="password-requirements">
                    <li>Minimum 8 characters</li>
                    <li>Minimum 1 special character (!, @, #, etc.)</li>
                    <li>Minimum 1 number and 1 letter</li>
                </ul>
                <FormulateInput
                    type="submit"
                    class="button"
                    :disabled="hasErrors || isLoading"
                    :label="isLoading ? 'Submitting...' : 'Set Password'"
                />
            </FormulateForm>
        </div>
    </div>
</template>

<script>
import { apiURL } from '../../util.js'
export default {
    name: "ResetPassword",
    data(){
        return{
            apiURL
        }
    },
    metaInfo: {
        title: 'Reset Password',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
    methods: {
        async resetPassword(data) {
            try {
                const tobeSubmitted = data;
                tobeSubmitted['code'] = this.$route.query.code;
                await this.axios.post(`${apiURL}/api/auth/reset-password`, tobeSubmitted);
            } catch(e) {
                this.error = true;
            } 
        },
    }
}
</script>

<style lang="scss" scoped>
h1{
    text-align: center;
}
.input-container{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    label{
        width: 30%;
        text-align: right;
        margin-right: 1rem;
    }
    .input{
        width: 300px;
        max-width: 100%;
    }
}

.password-requirements{
    margin-left: calc(30% + 16px);
    padding-left: unset;
    list-style-type: none;
    opacity: 0.5;
}

.button{
    display: flex;
    justify-content: center;
    margin-top: 30px
}

::v-deep .formulate-input[data-classification=button] button{
    border-color: #EF6A40;
    background: #EF6A40;
}

@media (max-width: $mobile) {
    .container{
        flex-wrap: wrap;
    }
}
</style>