var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"reset-password"}},[_c('div',{staticClass:"container"},[_c('h1',[_vm._v(" Enter New Password ")]),_c('FormulateForm',{on:{"submit":_vm.resetPassword},scopedSlots:_vm._u([{key:"default",fn:function({ isLoading, hasErrors }){return [_c('div',{staticClass:"input-container"},[_c('Label',[_vm._v("New Password")]),_c('FormulateInput',{staticClass:"input",attrs:{"type":"password","name":"password","placeholder":"Create Password","validation":"^required|min:8,length|checkLetters|checkSpecialCharacters","validation-rules":{
                        checkLetters: ({ value }) => value.match(/^(?=.*[a-zA-Z])(?=.*\d).+$/g),
                        checkSpecialCharacters: ({ value }) => value.match(/^.*[!@#$%^&*()_+].*$/g)
                    },"validation-name":"Password","validation-messages":{
                        checkLetters: 'Password requires at least 1 number and 1 letter.',
                        checkSpecialCharacters: 'Password requires at least 1 special character (!, @, #, etc.)'
                    }}})],1),_c('div',{staticClass:"input-container"},[_c('Label',[_vm._v("Confirm Password")]),_c('FormulateInput',{staticClass:"input",attrs:{"type":"password","name":"passwordConfirmation","placeholder":"Create Password","validation":"^required|min:8,length|checkLetters|checkSpecialCharacters","validation-rules":{
                        checkLetters: ({ value }) => value.match(/^(?=.*[a-zA-Z])(?=.*\d).+$/g),
                        checkSpecialCharacters: ({ value }) => value.match(/^.*[!@#$%^&*()_+].*$/g)
                    },"validation-name":"Password","validation-messages":{
                        checkLetters: 'Password requires at least 1 number and 1 letter.',
                        checkSpecialCharacters: 'Password requires at least 1 special character (!, @, #, etc.)'
                    }}})],1),_c('ul',{staticClass:"password-requirements"},[_c('li',[_vm._v("Minimum 8 characters")]),_c('li',[_vm._v("Minimum 1 special character (!, @, #, etc.)")]),_c('li',[_vm._v("Minimum 1 number and 1 letter")])]),_c('FormulateInput',{staticClass:"button",attrs:{"type":"submit","disabled":hasErrors || isLoading,"label":isLoading ? 'Submitting...' : 'Set Password'}})]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }